body {
    background: #F7FAFC;
}
.shadow-box{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border: none;
}
.mt-content{
    margin-top: 5.5rem!important;
}
.border-box{
    border-radius: 0px 8px 8px 8px;
}
.content-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.primary-bg-color{
    background-color: #044064 !important;
    border-color: #044064 !important;
}
.danger-bg-color{
    background-color: #FA0F00 !important;
    border-color: #FA0F00 !important;
}
.danger-bg-color:hover{
    background-color: #fa0f00c2 !important;
    border-color: #fa0f00bd !important;
}
.grey-bg-color{
    background-color: #E7E7E7;
}
.init-opt{
    display: none;
}
.info-bg-color{
    background-color: #249CF3;
    border-color: #249CF3;
}
.info-bg-color:hover{
    background-color: #249CF3;
    border-color: #249CF3;
}
.primary-bg-color:hover{
    background-color: #249CF3;
}
.primary-color{
    color:#044064;
}
.primary-color:hover{
    color:#249CF3;
}
input, button{
    height: 45px;
}
input:focus{
    border-color: #249CF3 !important;
}
.card-login{
    position: relative;
}

.content-loader{
    height: auto;
    max-width: 800px;
    width: 800px;
    margin: 30px auto;
}

.operative-day{
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 700;
}
.opt{
    width: 18%;
    float: left;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    margin: 10px 1%;
}
.blocked{
    background: #e1a9a9;
    color: #fff;
    cursor: not-allowed;
}
.form-select:focus{
    box-shadow: none;
}
.col-form{
    margin: 0 auto;
    overflow: hidden;
}
.col-phone{
    
    display: flex;
    justify-content: center;
}
.col-btn-action{
    text-align: center;
}
.schedule-title{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.dni-row{
    padding-left: 15px;
    padding-right: 15px;
}
.dni-row small{
    font-weight: bold;
    padding-top: 5px;
}

/*Input DNI*/
.btn-dni{
    margin-top: 7px;
}
/*Input DNI*/

.form-check{
    padding-left: 2.5em !important;
    text-align: center;
}

.form-check .form-check-input {
    float: inherit !important;
    margin-left: -1.5em !important;
    margin-right: 0.5rem !important;
}  

.form-select::-webkit-scrollbar {
    width: 5px;
}
/* Track */
.form-select::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
.form-select::-webkit-scrollbar-thumb {
    background: #888; 
}

.col-btn-actions .btn-action{
    margin-top: 10px;
    margin-bottom: 10px;
}

.col-btn-actions{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin: 0 auto;
}

@media(max-width: 700px) {
    .col-btn-actions{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        margin: 0 auto;
    }
    .col-btn-actions .btn-action{
        margin: 0;
    }
    .mt-content{
        margin: 0 !important;
        margin-top: 0 !important;
    }
    .col-dni-mobile{
        margin: 0 auto;
    }
    .init-opt{
        display: block;
    }
    body{
        background-color: #fff;
    }
    .content-loader{
        width: 80%;
        margin: 30px auto;
    }
}

